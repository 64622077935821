import React, { useState } from "react";
import styled from "styled-components";
import ThemeConfig from "../../style/ThemeConfig";

// Import Icons
import arrowDownFullIcon from "../../assets/images/icons/arrow-down-full.svg";
import arrowRightFullIcon from "../../assets/images/icons/arrow-right-full.svg";
import arrowDownIcon from "../../assets/images/icons/arrow-down.svg";
import arrowRightIcon from "../../assets/images/icons/arrow-right.svg";
import fileJsIcon from "../../assets/images/icons/file-js-icon.svg";
import folderOrangeIcon from "../../assets/images/icons/folder-orange-icon.svg";

const FileExplorer = () => {
	const [openFolderTree, setOpenFolderTree] = useState(true);
	const handleClickFolderTree = () => {
		setOpenFolderTree(!openFolderTree);
	};

	const [openFolder, setOpenFolder] = useState("bio");
	const handleClickFolder = (folderName) => {
		if (openFolder === "close") {
			setOpenFolder(folderName);
		} else if (openFolder !== folderName) {
			setOpenFolder(folderName);
		} else {
			setOpenFolder("close");
		}
	};

	return (
		<FullContainer>
			<ProyectName
				onClick={() => {
					handleClickFolderTree();
				}}
			>
				{openFolderTree ? (
					<img src={arrowDownFullIcon} alt="" />
				) : (
					<img src={arrowRightFullIcon} alt="" />
				)}
				<p>jgdev-portfolio</p>
			</ProyectName>
			{openFolderTree && (
				<FileTree>
					<Folder>
						<FolderName
							onClick={() => {
								handleClickFolder("bio");
							}}
						>
							{openFolder === "bio" ? (
								<img src={arrowDownIcon} alt="" />
							) : (
								<img src={arrowRightIcon} alt="" />
							)}
							<img src={folderOrangeIcon} alt="" />
							<p>bio</p>
						</FolderName>
						{openFolder === "bio" && (
							<File active={true}>
								<img src={fileJsIcon} alt="" />
								<p>hola.js</p>
							</File>
						)}
					</Folder>
				</FileTree>
			)}
		</FullContainer>
	);
};

export default FileExplorer;

const FullContainer = styled.div`
	width: 100%;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;

	border-bottom: 1px solid ${ThemeConfig.colors.lines};

	@media (max-width: 768px) {
		display: none;
	}
`;

const ProyectName = styled.div`
	width: 100%;
	height: 40px;
	min-height: 40px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	border-bottom: 1px solid ${ThemeConfig.colors.lines};
	padding-left: 12px;
	cursor: pointer;

	img {
		padding-right: 10px;
		opacity: 60%;
		transition: all 150ms ease-in-out;
	}

	p {
		margin: 0;
		transition: all 150ms ease-in-out;
	}

	&:hover {
		img {
			opacity: 100%;
		}

		p {
			color: ${ThemeConfig.colors.texts.white};
		}
	}
`;

const FileTree = styled.div`
	width: 100%;
	min-height: 220px;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;

	padding: 10px 0 40px 0;

	@media (max-width: 768px) {
		min-height: auto;
		padding: 10px 0 15px 0;
	}
`;

const Folder = styled.div`
	width: 100%;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
`;

const FolderName = styled.div`
	width: 100%;
	height: 30px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	cursor: pointer;

	img {
		padding-left: 10px;
		margin-top: -4px;
		opacity: 60%;
		transition: all 150ms ease-in-out;
	}

	p {
		padding-left: 10px;
		margin: 0;
		transition: all 150ms ease-in-out;
	}

	&:hover {
		img {
			opacity: 100%;
		}

		p {
			color: ${ThemeConfig.colors.texts.white};
		}
	}
`;

const File = styled.div`
	width: 100%;
	height: 32px;

	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	cursor: pointer;

	// background-color: ${ThemeConfig.colors.selectBG};

	background-color: ${({ active }) =>
		active ? ThemeConfig.colors.selectBG : "transparent"};

	img {
		padding-left: 35px;
		padding-right: 10px;
		margin-top: -4px;
		opacity: 60%;
		transition: all 150ms ease-in-out;
	}

	p {
		margin: 0;
		transition: all 150ms ease-in-out;
	}

	&:hover {
		img {
			opacity: 100%;
		}

		p {
			color: ${ThemeConfig.colors.texts.white};
		}
	}
`;
