import styled from "styled-components";
import ThemeConfig from "../../style/ThemeConfig";
import menuMobileIcon from "../../assets/images/icons/menu-mobile-icon.svg";
import closeIcon from "../../assets/images/icons/close-icon.svg";

const HeaderMenu = ({
	activePage,
	handleClick,
	handleClickMenu,
	menuIcon,
	changeMenuIcon,
}) => {
	return (
		<MainContainer>
			<NameTitle
				onClick={() => {
					handleClick("home");
				}}
				active={activePage === "home" && true}
			>
				julián-grosso.dev
			</NameTitle>
			<MobileIcon
				onClick={() => {
					handleClickMenu();
					changeMenuIcon();
				}}
			>
				{menuIcon ? (
					<img src={menuMobileIcon} alt="Menu" />
				) : (
					<img src={closeIcon} alt="Close Menu" />
				)}
			</MobileIcon>
			<Navbar>
				<Wrap>
					<ButtonMenu
						onClick={() => {
							handleClick("home");
						}}
						active={activePage === "home" && true}
						borderL={true}
						borderR={true}
					>
						_home
					</ButtonMenu>
					<ButtonMenu
						onClick={() => {
							handleClick("aboutme");
						}}
						active={activePage === "aboutme" && true}
						borderL={false}
						borderR={true}
					>
						_sobre-mi
					</ButtonMenu>
					{/* <ButtonMenu
						onClick={() => {
							handleClick("projects");
						}}
						active={activePage === "projects" && true}
						borderL={false}
						borderR={true}
					>
						_proyectos
					</ButtonMenu> */}
				</Wrap>
				{/* <ButtonMenu
					onClick={() => {
						handleClick("contact");
					}}
					active={activePage === "contact" && true}
					borderL={true}
					borderR={false}
				>
					_contacto
				</ButtonMenu> */}
			</Navbar>
		</MainContainer>
	);
};

export default HeaderMenu;

const MainContainer = styled.header`
	width: 100%;
	height: 55px;
	min-height: 55px;
	max-height: 55px;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	border-bottom: 1px solid ${ThemeConfig.colors.lines};

	user-select: none;
`;

const Navbar = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	@media (max-width: 768px) {
		display: none;
	}
`;

const Wrap = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

const NameTitle = styled.a`
	min-width: 300px;
	font-size: 1rem;
	font-weight: normal;
	color: ${ThemeConfig.colors.texts.default};

	padding: 0 20px;

	transition: 150ms all ease-in-out;

	&:hover {
		color: #fff;
		cursor: pointer;
	}

	@media (max-width: 768px) {
		min-width: auto;
		width: auto;
	}
`;

const ButtonMenu = styled.a`
	font-size: 1rem;
	font-weight: normal;

	color: ${(props) =>
		props.active ? "#fff" : ThemeConfig.colors.texts.default};

	height: 55px;
	padding: 0 25px;

	display: flex;
	justify-content: center;
	align-items: center;

	border-left: 1px solid
		${(props) => (props.borderL ? ThemeConfig.colors.lines : "transparent")};

	border-right: 1px solid
		${(props) => (props.borderR ? ThemeConfig.colors.lines : "transparent")};

	border-bottom: 3px solid
		${(props) =>
			props.active ? ThemeConfig.colors.hoverButtonLine : "transparent"};

	transition: 150ms all ease-in-out;

	&:hover {
		color: #fff;
		cursor: pointer;
		border-bottom: 3px solid ${ThemeConfig.colors.hoverButtonLine};
	}
`;

const MobileIcon = styled.div`
	display: none;

	user-select: none;

	@media screen and (max-width: 768px) {
		display: block;
		margin: 2px 20px 0 0;
		user-select: none;
		cursor: pointer;
	}
`;
