const aboutMePages = [
    {
        id: "aboutMe",
        fileName: "hola.js",
        text: `/*
		Emprendedor, inquieto y proactivo. Me motivan los desafíos y encontrar nuevas formas de resolverlos. Aprender haciendo y re-aprender con los resultados.
		
		Creo en la fuerza del trabajo en equipo y me encanta ser parte de proyectos con objetivos innovadores que desafían lo existente.

		Los datos son la base para el crecimiento, por eso como Data Engineer y Data Product Manager ayudo a empresas a evolucionar e impulsarse con datos.

		Experiencia previa:
		Hasta finales de 2021 me desempeñé como director y diseñador de sonido. Cuento con experiencia en coordinación de proyectos y liderazgo de equipos. Desde 2008 participé en distintos tipos de proyectos sonoros y audiovisuales entre películas, series, documentales, publicidades, producciones discográficas y contenido web. Además tuve experiencias en radios, estudios de grabación, djing y obras de teatro.
		*/`,
    },
];

export default aboutMePages;
