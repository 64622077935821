import styled from "styled-components";
import ThemeConfig from "../../style/ThemeConfig";

// Import Icon
import chevronRight from "../../assets/images/icons/chevron-right.svg";

const ChevronLink = ({ text, handleClick }) => {
	return (
		<MainContainer
			onClick={() => {
				handleClick("aboutme");
			}}
		>
			<p>{text}</p>
			<img src={chevronRight} alt={`Link a ${text}`} />
		</MainContainer>
	);
};

export default ChevronLink;

const MainContainer = styled.div`
	position: absolute;
	top: 50%;
	right: 20px;

	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;

	height: 80px;
	margin-top: -40px;
	padding: 10px;

	user-select: none;
	cursor: pointer;

	p {
		font-size: 1rem;
		color: ${ThemeConfig.colors.texts.white};
		opacity: 0%;
		transition: all 150ms ease-in-out;
	}

	img {
		height: 60px;
		width: auto;
		margin-left: 10px;
		opacity: 50%;
		transition: all 150ms ease-in-out;
	}

	&:hover {
		p {
			opacity: 100%;
		}

		img {
			opacity: 100%;
		}
	}

	@media (max-width: 768px) {
		display: none;
	}
`;
