import styled from "styled-components";
import ThemeConfig from "../../style/ThemeConfig";

const CodeLine = ({ text, handleClick, maxWidth }) => {
    return (
        <MainContainer
            maxWidth={maxWidth}
            onClick={() => {
                handleClick("aboutme");
            }}
        >
            {text.commentText && <CommentText>{text.commentText}</CommentText>}
            <CodeLineWrapper>
                {text.varType && <VarType>{text.varType}</VarType>}
                {text.varName && <VarName>{text.varName}</VarName>}
                {text.equalSimbol && <EqualSimbol>{text.equalSimbol}</EqualSimbol>}
                {text.string && <String>{text.string}</String>}
                {text.fuctionPart01 && (
                    <FunctionStructure>{"(" + text.fuctionPart01 + ") => {"}</FunctionStructure>
                )}
                {text.fuctionPart02 && (
                    <FunctionBody>
                        <span>return </span>
                        {text.fuctionPart02}
                    </FunctionBody>
                )}
                {text.fuctionPart02 && <FunctionStructure>{"};"}</FunctionStructure>}
            </CodeLineWrapper>
            <Button>{text.buttonMobileText}</Button>
        </MainContainer>
    );
};

export default CodeLine;

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    max-width: ${({ maxWidth }) => maxWidth}px;
    margin-left: 60px;
    margin-bottom: 30px;

    font-size: 0.9rem;
    font-weight: 500;
    line-height: 24px;
    text-shadow: 0px 0px 4px #000;

    padding: 25px;
    margin-top: -40px;
    border-radius: 10px;
    border: 1px solid ${ThemeConfig.colors.lines};
    background-color: ${ThemeConfig.colors.codeSnippedBG};

    user-select: none;
    cursor: pointer;

    transition: all 150ms ease-in-out;
    &:hover {
        border: 1px solid ${ThemeConfig.colors.projectCardButtonHoverBorder};
        background-color: ${ThemeConfig.colors.codeSnippedHoverBG};
    }

    @media (min-width: 1900px) {
        margin-left: 100px;
    }

    @media (min-width: 1024px) and (max-width: 1900px) {
        margin-left: 60px;
    }

    @media (max-width: 1024px) {
        padding: 20px;
        margin-left: 40px;
        max-width: ${({ maxWidth }) => maxWidth - 52}px;

        font-size: 0.8rem;
        line-height: 20px;
    }

    @media (max-width: 768px) {
        max-width: ${({ maxWidth }) => maxWidth - 54}px;
        margin-left: 25px;
        margin-right: 25px;
        margin-bottom: 0px;

        &:hover {
            background-color: ${ThemeConfig.colors.codeSnippedBG};
        }
    }

    @media (max-width: 500px) {
        padding: 0;
        margin-top: 0;
        border: none;
        border-radius: none;
        background-color: transparent;
        max-width: ${({ maxWidth }) => maxWidth - 110}px;
        margin-left: 25px;

        &:hover {
            background-color: transparent;
        }
    }
`;

const CodeLineWrapper = styled.a`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @media (max-width: 500px) {
        display: none;
    }
`;

const CommentText = styled.p`
    color: ${ThemeConfig.colors.texts.comment};
    margin: 0 0 10px 0;

    @media (max-width: 500px) {
        display: none;
    }
`;

const VarType = styled.p`
    color: ${ThemeConfig.colors.texts.varType};
    margin: 0 10px 0 0;
`;

const VarName = styled.p`
    color: ${ThemeConfig.colors.texts.varName};
    margin: 0 10px 0 0;
`;

const EqualSimbol = styled.p`
    color: ${ThemeConfig.colors.texts.equalSimbol};
    margin: 0 10px 0 0;
`;

const String = styled.p`
    color: ${ThemeConfig.colors.texts.string};
    margin: 0;
`;

const FunctionStructure = styled.p`
    color: ${ThemeConfig.colors.texts.string};
    margin: 0;
`;

const FunctionBody = styled.p`
    color: ${ThemeConfig.colors.texts.string};
    margin: 0;
    padding-left: 2.5rem;

    span {
        color: ${ThemeConfig.colors.texts.varType};
    }
`;

const Button = styled.a`
    display: none;

    height: 45px;
    padding: 8px 10px;

    font-size: 0.9rem;
    color: ${ThemeConfig.colors.texts.titleh2};
    text-shadow: none;

    border: solid 2px ${ThemeConfig.colors.projectCardButtonHoverBorder};
    border-radius: 8px;
    background-color: transparent;

    transition: all 150ms ease-in-out;
    &:hover {
        color: ${ThemeConfig.colors.texts.white};
        background-color: ${ThemeConfig.colors.projectCardButton};
        border: solid 2px ${ThemeConfig.colors.projectCardButtonHoverBorder};
    }

    &:active {
        color: ${ThemeConfig.colors.texts.white};
        background-color: ${ThemeConfig.colors.projectCardButton};
        border: solid 2px ${ThemeConfig.colors.projectCardButtonHoverBorder};
    }

    &:focus {
        color: ${ThemeConfig.colors.texts.white};
        background-color: ${ThemeConfig.colors.projectCardButton};
        border: solid 2px ${ThemeConfig.colors.projectCardButtonHoverBorder};
    }

    @media (max-width: 500px) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
`;
