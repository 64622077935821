import { useEffect, useState } from "react";
import styled from "styled-components";
import Footer from "./components/Footer";
import HeaderMenu from "./components/Header";
import MobileMenu from "./components/Header/mobile";
import AboutMe from "./pages/AboutMe";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
// import Projects from "./pages/Projects";
import ThemeConfig from "./style/ThemeConfig";

// >>Version: 1.1

function App() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    useEffect(() => {
        window.addEventListener("resize", () => {
            setWindowWidth(window.innerWidth);
            setWindowHeight(window.innerHeight);
        });
    }, []);

    const [activePage, setActivePage] = useState("home");

    const handleClickChangePage = (activePage) => {
        setActivePage(activePage);
    };

    const [mobileMenu, setMobileMenu] = useState(false);

    const handleClickMenu = () => {
        setMobileMenu(!mobileMenu);
    };

    const [menuIcon, setMenuIcon] = useState(true);

    const changeMenuIcon = () => {
        setMenuIcon(!menuIcon);
    };

    return (
        <AppContainer windowHeight={windowHeight}>
            <MainContainer>
                <HeaderMenu
                    activePage={activePage}
                    handleClick={handleClickChangePage}
                    handleClickMenu={handleClickMenu}
                    menuIcon={menuIcon}
                    changeMenuIcon={changeMenuIcon}
                />
                {mobileMenu && (
                    <MobileMenu
                        activePage={activePage}
                        handleClick={handleClickChangePage}
                        handleClickMenu={handleClickMenu}
                        changeMenuIcon={changeMenuIcon}
                    />
                )}
                <PageContainer>
                    {activePage === "home" && <Home handleClick={handleClickChangePage} />}
                    {activePage === "aboutme" && <AboutMe windowWidth={windowWidth} />}
                    {/* {activePage === "projects" && <Projects />} */}
                    {activePage === "contact" && <Contact />}
                </PageContainer>
                <Footer />
            </MainContainer>
        </AppContainer>
    );
}

export default App;

const AppContainer = styled.main`
    width: 100vw;
    height: ${({ windowHeight }) => (windowHeight ? windowHeight + "px" : "100vh")};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const MainContainer = styled.main`
    width: 92%;
    height: 90%;
    max-width: 1782px;
    max-height: 942px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: relative;

    background: ${ThemeConfig.colors.mainBackground};
    border: 1px solid ${ThemeConfig.colors.lines};
    border-radius: 8px;

    @media (max-width: 768px) {
        width: 90%;
        height: 95%;
    }
`;

const PageContainer = styled.section`
    width: 100%;
    height: 100%;

    overflow-y: hidden;
`;
