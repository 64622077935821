import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ThemeConfig from "../style/ThemeConfig";
import aboutmeData from "../assets/aboutmeData";

// Import Icons
import professionalInfoIcon from "../assets/images/icons/professional-info-icon.svg";
import closeIcon from "../assets/images/icons/close-icon.svg";

// Import Components
import ContactList from "../components/AboutMe/ContactList";
import FileExplorer from "../components/AboutMe/FileExplorer";

//TODO-01: Rever el problema de altura del texto/numero de lineas en mobile

const AboutMe = ({ windowWidth }) => {
	const aboutMePages = aboutmeData;

	const [sideBarOpen, setSideBarOpen] = useState(true);

	const handleSideBarState = () => {
		setSideBarOpen(!sideBarOpen);
	};

	let linesNumber = 0;

	const textRef = useRef(null);

	const [codeEditorLinesNum, setCodeEditorLinesNum] = useState(0);

	useEffect(() => {
		const textHeight = textRef.current.clientHeight;
		setCodeEditorLinesNum(Math.floor(textHeight / 26));
	}, [windowWidth]);

	useEffect(() => {
		const textHeight = textRef.current.clientHeight;
		setCodeEditorLinesNum(Math.floor(textHeight / 26));
	}, [sideBarOpen]);

	return (
		<MainContainer>
			<SideBar>
				<SideIconsBar>
					<IconLink
						active={sideBarOpen}
						onClick={() => {
							handleSideBarState();
						}}
					>
						<img src={professionalInfoIcon} alt="" />
					</IconLink>
				</SideIconsBar>
				{sideBarOpen && (
					<SideExplorerBar>
						<FileExplorer />
						<ContactList />
					</SideExplorerBar>
				)}
			</SideBar>
			<CodeViewerSection>
				<TopBar>
					<FileTab>
						<p>{aboutMePages[0].fileName}</p>
						<img src={closeIcon} alt="" />
					</FileTab>
				</TopBar>
				<CodeViewer>
					<LineNumberList>
						{Array(codeEditorLinesNum)
							.fill(1)
							.map(() => {
								let i = ++linesNumber;
								return <p key={i}>{i}</p>;
							})}
					</LineNumberList>
					<CodeText>
						<p ref={textRef}>
							{aboutMePages[0].text.split("\n").map((text) => {
								const randomId = Math.random();
								return (
									<span key={randomId}>
										{text}
										<br />
									</span>
								);
							})}
						</p>
					</CodeText>
				</CodeViewer>
			</CodeViewerSection>
		</MainContainer>
	);
};

export default AboutMe;

const MainContainer = styled.section`
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;

	@media (max-width: 768px) {
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
	}
`;

const SideBar = styled.div`
	height: 100%;

	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;

	user-select: none;

	@media (max-width: 768px) {
		width: 100%;
	}
`;

const SideIconsBar = styled.div`
	width: 20%;
	height: 100%;
	min-width: 60px;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	border-right: 1px solid ${ThemeConfig.colors.lines};

	@media (max-width: 768px) {
		display: none;
	}
`;

const IconLink = styled.a`
	width: 100%;
	height: 55px;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	border-right: 3px solid
		${({ active }) =>
			active ? ThemeConfig.colors.hoverButtonLine : "transparent"};
	opacity: 60%;
	transition: 150ms all ease-in-out;

	&:hover {
		opacity: 100%;
		border-right: 3px solid ${ThemeConfig.colors.hoverButtonLine};
		cursor: pointer;
	}

	img {
		margin-right: -3px;
	}
`;

const SideExplorerBar = styled.div`
	width: 80%;
	min-width: 241px;
	height: 100%;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	border-right: 1px solid ${ThemeConfig.colors.lines};

	font-size: 0.9rem;
	color: ${ThemeConfig.colors.texts.default};

	@media (max-width: 768px) {
		width: 100%;
		border-right: none;
		border-bottom: 1px solid ${ThemeConfig.colors.lines};
	}
`;

const CodeViewerSection = styled.div`
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	@media (max-width: 768px) {
		align-items: flex-start;
	}
`;

const TopBar = styled.div`
	width: 100%;
	height: 40px;

	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;

	border-bottom: 1px solid ${ThemeConfig.colors.lines};
`;

const FileTab = styled.div`
	height: 100%;
	padding: 0 15px 0 20px;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	border-right: 1px solid ${ThemeConfig.colors.lines};

	user-select: none;

	p {
		font-size: 0.9rem;
		color: ${ThemeConfig.colors.texts.default};
		padding-right: 30px;
	}

	img {
		max-width: 12px;
		opacity: 80%;
		cursor: pointer;

		transition: 150ms all ease-in-out;
		&:hover {
			opacity: 100%;
		}
	}
`;

const CodeViewer = styled.div`
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-content: flex-start;

	padding-top: 20px;

	gap: 3rem;

	font-size: 1rem;
	color: ${ThemeConfig.colors.texts.comment};

	overflow-y: scroll;

	scrollbar-width: none;
	-ms-overflow-style: none;
	&::-webkit-scrollbar {
		width: 14px;
	}

	&::-webkit-scrollbar-track {
		background-color: transparent;
		border-left: solid 1px ${ThemeConfig.colors.lines};
	}

	&::-webkit-scrollbar-thumb {
		background-color: ${ThemeConfig.colors.lines};
		background-clip: content-box;
	}

	@media (max-width: 768px) {
		gap: 20px;

		scrollbar-width: none;
		-ms-overflow-style: none;
		&::-webkit-scrollbar {
			display: none;
		}
	}
`;

const LineNumberList = styled.div`
	width: 60px;
	min-width: 60px;
	max-width: 60px;
	padding-top: 2px;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-end;

	gap: 5px;
	color: ${ThemeConfig.colors.texts.lineNumbers};
	user-select: none;

	p {
		text-align: right;
		margin: 0;
		padding: 0;
	}

	@media (max-width: 768px) {
		width: 35px;
		min-width: 35px;
		max-width: auto;
	}
`;

const CodeText = styled.div`
	height: 100%;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;

	padding-right: 20px;

	p {
		text-align: left;
		margin: 0;
		padding: 0;
		line-height: 26px;
		max-width: 570px;
		padding-bottom: 20px;
	}

	//TODO-01
	@media (max-width: 768px) {
		p {
			padding-bottom: 196px;
		}
	}
`;
