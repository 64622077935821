import React, { useState } from "react";
import styled from "styled-components";
import ThemeConfig from "../../style/ThemeConfig";

// Import Icons
import arrowDownFullIcon from "../../assets/images/icons/arrow-down-full.svg";
import arrowRightFullIcon from "../../assets/images/icons/arrow-right-full.svg";
import mailIcon from "../../assets/images/icons/mail-icon.svg";
import linkIcon from "../../assets/images/icons/link-icon.svg";

const ContactList = () => {
	const [openList, setOpenList] = useState(true);

	const handleClickList = () => {
		setOpenList(!openList);
	};

	return (
		<FullContainer>
			<ContactTitle
				onClick={() => {
					handleClickList();
				}}
			>
				{openList ? (
					<img src={arrowDownFullIcon} alt="" />
				) : (
					<img src={arrowRightFullIcon} alt="" />
				)}
				<p>contacto</p>
			</ContactTitle>
			{openList && (
				<LinksWrap>
					<ContactLink style={{ cursor: "text", userSelect: "text" }}>
						<img src={mailIcon} alt="Email de Julian" />
						<p>hola@juliangrosso.com.ar</p>
					</ContactLink>
					<ContactLink
						href="https://www.linkedin.com/in/juliangrosso"
						target="_blank"
					>
						<img src={linkIcon} alt="Julian en LinkedIn" />
						<p>LinkedIn/juliangrosso</p>
					</ContactLink>
					{/* <ContactLink href="https://github.com/JulianGrosso" target="_blank">
						<img src={linkIcon} alt="Julian en GitHub" />
						<p>GitHub/juliangrosso</p>
					</ContactLink> */}
				</LinksWrap>
			)}
		</FullContainer>
	);
};

export default ContactList;

const FullContainer = styled.div`
	width: 100%;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
`;

const ContactTitle = styled.div`
	width: 100%;
	height: 40px;
	min-height: 40px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	border-bottom: 1px solid ${ThemeConfig.colors.lines};
	padding-left: 12px;
	cursor: pointer;

	img {
		padding-right: 10px;
		opacity: 60%;
		transition: all 150ms ease-in-out;
	}

	p {
		margin: 0;
		transition: all 150ms ease-in-out;
	}

	&:hover {
		img {
			opacity: 100%;
		}

		p {
			color: ${ThemeConfig.colors.texts.white};
		}
	}
`;

const LinksWrap = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;

	padding: 10px 0 0 0;

	@media (max-width: 768px) {
		padding: 10px 0 15px 0;
	}
`;

const ContactLink = styled.a`
	width: 100%;
	height: 30px;
	margin-top: 5px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	cursor: pointer;

	img {
		width: auto;
		height: 16px;
		margin: -2px 10px 0 10px;
		opacity: 60%;
		transition: all 150ms ease-in-out;
	}

	p {
		font-size: 0.75rem;
		font-weight: 600;
		margin: 0;
		transition: all 150ms ease-in-out;
		user-select: auto;
	}

	&:hover {
		img {
			opacity: 100%;
		}

		p {
			color: ${ThemeConfig.colors.texts.white};
		}
	}
`;
