const ThemeConfig = {
	colors: {
		pageBackground: "#010C15",
		mainBackground: "#011627",
		selectBG: "#152837",
		codeSnippedBG: "#011221",
		codeSnippedHoverBG: "#021a2f",
		projectCardBG: "#011221",
		projectCardButton: "#152837",
		projectCardButtonHoverBorder: "#5565E8",
		lines: "#1E2D3D",
		hoverButtonLine: "#FEA55F",
		texts: {
			white: "#ffffff",
			default: "#6f8eae",
			lineNumbers: "#405264",
			varType: "#4D5BCE",
			varName: "#43D9AD",
			equalSimbol: "#eaeaea",
			string: "#E99287",
			comment: "#607B96",
			titleh1: "#ffffff",
			titleh2: "#43D9AD",
			projectTitle: "#5565E8",
		},
	},
};

export default ThemeConfig;
