import styled from "styled-components";
import ThemeConfig from "../../style/ThemeConfig";

const MobileMenu = ({
	activePage,
	handleClick,
	handleClickMenu,
	changeMenuIcon,
}) => {
	return (
		<MainContainer>
			<ButtonMenu
				onClick={() => {
					handleClick("home");
					handleClickMenu();
					changeMenuIcon();
				}}
				active={activePage === "home" && true}
			>
				_home
			</ButtonMenu>
			<ButtonMenu
				onClick={() => {
					handleClick("aboutme");
					handleClickMenu();
					changeMenuIcon();
				}}
				active={activePage === "aboutme" && true}
			>
				_sobre-mi
			</ButtonMenu>
			{/* <ButtonMenu
				onClick={() => {
					handleClick("projects");
					handleClickMenu();
					changeMenuIcon();
				}}
				active={activePage === "projects" && true}
			>
				_proyectos
			</ButtonMenu> */}
			{/* <ButtonMenu
				onClick={() => {
					handleClick("contact");
					handleClickMenu();
					changeMenuIcon();
				}}
				active={activePage === "contact" && true}
			>
				_contacto
			</ButtonMenu> */}
		</MainContainer>
	);
};

export default MobileMenu;

const MainContainer = styled.section`
	display: none;

	@media screen and (max-width: 768px) {
		position: absolute;
		top: 55px;
		width: 100%;
		height: calc(100% - 100px);
		z-index: 9999;

		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;

		background-color: ${ThemeConfig.colors.mainBackground};

		user-select: none;
	}
`;

const ButtonMenu = styled.a`
	font-size: 1rem;
	font-weight: normal;
	color: ${(props) =>
		props.active ? "#fff" : ThemeConfig.colors.texts.default};

	height: 50px;
	width: 100%;
	padding-left: 20px;

	display: flex;
	justify-content: flex-start;
	align-items: center;

	border-bottom: 3px solid
		${(props) =>
			props.active
				? ThemeConfig.colors.hoverButtonLine
				: ThemeConfig.colors.lines};

	transition: 150ms all ease-in-out;

	&:hover {
		color: #fff;
		cursor: pointer;
		border-bottom: 3px solid ${ThemeConfig.colors.hoverButtonLine};
	}
`;
