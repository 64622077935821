import styled from "styled-components";
import ThemeConfig from "../../style/ThemeConfig";
import githubIcon from "../../assets/images/icons/github-icon.svg";
import linkedinIcon from "../../assets/images/icons/linkedin-icon.svg";
import twitterIcon from "../../assets/images/icons/twitter-icon.svg";

const Footer = () => {
	return (
		<MainContainer>
			<Wrap>
				<Text borderL={false} borderR={true}>
					encontrame-en:
				</Text>
				<IconLink href="https://github.com/JulianGrosso" target="_blank">
					<img src={githubIcon} alt="Julian en GitHub" />
				</IconLink>
				<IconLink href="https://twitter.com/juliangrosso_" target="_blank">
					<img src={twitterIcon} alt="Julian en Twitter" />
				</IconLink>

				{/* <IconLink
					href="https://www.linkedin.com/in/juliangrosso"
					target="_blank"
				>
					<img src={linkedinIcon} alt="Julian en LinkedIn" />
				</IconLink> */}
			</Wrap>
			<LargeIconLink
				href="https://www.linkedin.com/in/juliangrosso"
				target="_blank"
				borderL={true}
				borderR={false}
			>
				<img src={linkedinIcon} alt="Julian en LinkedIn" />
				<p>/juliangrosso</p>
			</LargeIconLink>
			{/* <LargeIconLink
				href="https://github.com/JulianGrosso"
				target="_blank"
				borderL={true}
				borderR={false}
			>
				<img src={githubIcon} alt="Julian en GitHub" />
				<p>@juliangrosso</p>
			</LargeIconLink> */}
		</MainContainer>
	);
};

export default Footer;

const MainContainer = styled.footer`
	width: 100%;
	height: 45px;
	min-height: 45px;
	max-height: 45px;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	border-top: 1px solid ${ThemeConfig.colors.lines};

	@media (max-width: 768px) {
		justify-content: flex-start;
	}

	user-select: none;
`;

const Wrap = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	min-width: 301px;

	@media (max-width: 768px) {
		min-width: auto;
	}
`;

const Text = styled.p`
	font-size: 1rem;
	font-weight: normal;
	color: ${ThemeConfig.colors.texts.default};

	height: 45px;
	width: 100%;
	padding: 0 20px;

	display: flex;
	justify-content: center;
	align-items: center;

	border-left: 1px solid
		${(props) => (props.borderL ? ThemeConfig.colors.lines : "transparent")};

	border-right: 1px solid
		${(props) => (props.borderR ? ThemeConfig.colors.lines : "transparent")};

	transition: 150ms all ease-in-out;

	cursor: default;
	user-select: none;

	@media (max-width: 768px) {
		display: none;
	}
`;

const IconLink = styled.a`
	width: 50px;
	min-width: 50px;
	height: 45px;
	min-height: 45px;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	border-right: 1px solid ${ThemeConfig.colors.lines};
	border-bottom: 3px solid transparent;
	transition: 150ms all ease-in-out;

	&:hover {
		border-bottom: 3px solid ${ThemeConfig.colors.hoverButtonLine};
		cursor: pointer;
	}

	img {
		margin-bottom: -3px;
	}
`;

const LargeIconLink = styled.a`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	height: 45px;
	padding: 0 15px;

	border-left: 1px solid
		${(props) => (props.borderL ? ThemeConfig.colors.lines : "transparent")};

	border-right: 1px solid
		${(props) => (props.borderR ? ThemeConfig.colors.lines : "transparent")};

	border-bottom: 3px solid transparent;
	transition: 150ms all ease-in-out;

	img {
		padding-right: 10px;
	}

	p {
		font-size: 1rem;
		font-weight: normal;
		color: ${ThemeConfig.colors.texts.default};
	}

	&:hover {
		border-bottom: 3px solid ${ThemeConfig.colors.hoverButtonLine};
		cursor: pointer;
	}

	@media (max-width: 768px) {
		width: 50px;
		padding: 0;
		border-left: none;
		border-right: 1px solid
			${(props) => (props.borderR ? "transparent" : ThemeConfig.colors.lines)};

		img {
			padding: 0;
			margin-bottom: -3px;
		}

		p {
			display: none;
		}
	}
`;
