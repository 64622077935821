import styled from "styled-components";
import ThemeConfig from "../style/ThemeConfig";
import bg01 from "../assets/images/background/background-01.jpg";
import bg02 from "../assets/images/background/background-02.jpg";
import CodeLine from "../components/Home/CodeLine";
import ChevronLink from "../components/Home/ChevronLink";

const Home = ({ handleClick }) => {
	return (
		<MainContainer>
			<Wrapper>
				<TextComment>{"// "}Hola!, soy</TextComment>
				<TitleH1>Julián Grosso</TitleH1>
				<TitleH2>{">>>>>>>>"}</TitleH2>
			</Wrapper>
			<CodeLine
				text={{
					commentText: "// conocé mas sobre mí:",
					varType: "const",
					varName: "aboutMeLink",
					equalSimbol: "=",
					fuctionPart01: " ",
					fuctionPart02: `<Link to="about-me">Sobre Mí</Link>;`,
					buttonMobileText: "conocé-mas-sobre-mí.py",
				}}
				handleClick={handleClick}
				maxWidth={475}
			/>
			<ChevronLink text={"sobre-mi"} handleClick={handleClick} />
		</MainContainer>
	);
};

export default Home;

const MainContainer = styled.section`
	width: 100%;
	height: 100%;

	background-image: url(${bg01});
	background-repeat: no-repeat;
	background-size: cover;
	background-position: left;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;

	position: relative;

	overflow-y: scroll;
	scrollbar-width: none;
	-ms-overflow-style: none;
	&::-webkit-scrollbar {
		display: none;
	}

	@media (min-width: 1900px) {
		gap: 230px;
	}

	@media (min-width: 1024px) and (max-width: 1900px) {
		gap: 130px;
	}

	@media (max-width: 1024px) {
		gap: 120px;
	}

	@media (max-width: 768px) {
		background-position: left;
		background-position: -120px;
		gap: 100px;
	}

	@media (max-width: 500px) {
		background-image: url(${bg02});
		background-position: center;

		justify-content: center;
		gap: 120px;
	}
`;

const Wrapper = styled.div`
	text-align: left;
	margin-left: 60px;
	margin-top: 160px;
	user-select: none;

	@media (min-width: 1900px) {
		margin-top: 220px;
		margin-left: 100px;
	}

	@media (min-width: 1024px) and (max-width: 1900px) {
		margin-top: 170px;
		margin-left: 60px;
	}

	@media (max-width: 1024px) {
		max-width: 300px;
		margin-top: 120px;
		margin-left: 40px;
	}

	@media (max-width: 768px) {
		max-width: 270px;
		margin-top: 120px;
		margin-left: 25px;
	}

	@media (max-width: 500px) {
		max-width: 270px;
		margin-top: 0;
		margin-left: 25px;
	}
`;

const TitleH1 = styled.h1`
	font-size: 64px;
	font-weight: 400;
	letter-spacing: -1px;
	word-spacing: -15px;
	margin: 0;

	@media (max-width: 1024px) {
		font-size: 62px;
		line-height: 60px;
		margin: 10px 0;
	}

	@media (max-width: 768px) {
		font-size: 62px;
		line-height: 60px;
	}
`;

const TitleH2 = styled.h2`
	font-size: 1.8rem;
	font-weight: 400;
	margin: 0;
	color: ${ThemeConfig.colors.texts.titleh2};

	@media (max-width: 1024px) {
		font-size: 20px;
	}

	@media (max-width: 768px) {
		font-size: 18px;
	}
`;

const TextComment = styled.p`
	font-size: 16px;
	color: ${ThemeConfig.colors.texts.comment};
	margin: 0 0 5px 0;
`;
