import styled from "styled-components";
// import ThemeConfig from "../style/ThemeConfig";

const Contact = () => {
	return (
		<MainContainer>
			<Wrapper>
				<TitleH1>_contacto</TitleH1>
			</Wrapper>
		</MainContainer>
	);
};

export default Contact;

const MainContainer = styled.section`
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
`;

const Wrapper = styled.div`
	text-align: left;
	margin-left: 60px;
	margin-top: -60px;
	user-select: none;
`;

const TitleH1 = styled.h1`
	font-size: 24px;
	font-weight: 400;
	letter-spacing: -1px;
	margin: 0;
`;

// const TitleH2 = styled.h2`
// 	font-size: 1.8rem;
// 	font-weight: 400;
// 	margin: 0;
// 	color: ${ThemeConfig.colors.texts.titleh2};
// `;

// const TextComment = styled.p`
// 	font-size: 16px;
// 	color: ${ThemeConfig.colors.texts.comment};
// 	margin: 0 0 5px 10px;
// `;
